<template>
  <v-card
    :class="{
      highlighted: card.highlighted,
      'linked-card': isCardLinked(card),
    }"
  >
    <!-- Header de la carte -->
    <div
      class="d-flex align-center justify-space-between pa-0"
      style="cursor: pointer; height: 34px"
      :style="{ backgroundColor: getColorByTeam(card.carte.impactType) }"
    >
      <v-btn v-if="!isFromAllYear" icon dark @click.stop="toggleCardShow">
        <v-icon>{{
          isCardShown ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
      <div
        class="white--text searchable text-truncate px-2"
        style="font-size: 12px"
      >
        {{ card.carte.libelle }}
      </div>
      <v-menu :offset-y="isTermine" :offset-x="!isTermine" top>
        <template v-slot:activator="{ on }">
          <v-btn
        :offset-y="isTermine" 
        :offset-x="!isTermine" 
        top
            dark
            icon
            v-on="on"
            @click.stop
            v-if="(canWriteOwnTasks || canWriteTeamTasks || canEditOthersTasks) && !isFromAllYear"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
          <v-btn v-else icon dark style="visibility: hidden">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list v-if="!isTermine">
          <v-list-item
            @click="$emit('assign-task', card)"
            v-if="!isCardAssigned(card)"
          >
            <v-icon class="mr-2" color="teal">mdi-account-plus</v-icon>
            <v-list-item-title class="teal--text">Assigner</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('unassign-task', card._id)" v-else>
            <v-icon class="mr-2" color="teal">mdi-account-minus</v-icon>
            <v-list-item-title class="teal--text"
              >Désassigner</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="$emit('estimate-duration', card)">
            <v-icon class="mr-2" color="teal">mdi-timer</v-icon>
            <v-list-item-title class="teal--text">
              {{
                card.carte.duree.duree
                  ? "Modifier la durée estimée"
                  : "Durée estimée"
              }}
            </v-list-item-title>
          </v-list-item>

            <v-list-item @click="$emit('date-previsionnel', card)">
            <v-icon class="mr-2" color="teal">mdi-calendar</v-icon>
            <v-list-item-title class="teal--text">
              {{
                card.carte.datePrevisionnel.previsionDate
                  ? "Modifier la date prévisionnelle"
                  : "Date prévisionnelle"
              }}
            </v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>
          <v-list-item @click="$emit('open-detail', card._id)">
            <v-icon class="mr-2" color="indigo">mdi-information</v-icon>
            <v-list-item-title class="indigo--text">Détails</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('update-card', card._id)">
            <v-icon class="mr-2" color="warning"
              >mdi-square-edit-outline</v-icon
            >
            <v-list-item-title class="warning--text"
              >Modifier</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="$emit('delete-card', card._id)">
            <v-icon class="mr-2" color="error">mdi-delete</v-icon>
            <v-list-item-title class="error--text">Supprimer</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list v-else-if="isTermine">
          <v-list-item @click="$emit('reset-status', card._id)">
            <v-icon class="mr-2" color="primary">mdi-undo</v-icon>
            <v-list-item-title class="primary--text"
              >Remettre en cours</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="$emit('open-detail', card._id)">
            <v-icon class="mr-2" color="indigo">mdi-information</v-icon>
            <v-list-item-title class="indigo--text">Détails</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('delete-card', card._id)">
            <v-icon class="mr-2" color="error">mdi-delete</v-icon>
            <v-list-item-title class="error--text">Supprimer</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- Contenu de la carte -->
    <v-expand-transition>
      <v-card-text class="card-content pt-3" v-if="isCardShown || isFromAllYear" @click.stop="$emit('open-chat', card)">
        <v-row dense align="center" class="mb-1">
          <v-col cols="auto">
            <v-avatar size="24" color="primary">
              <v-icon dark small>mdi-account</v-icon>
            </v-avatar>
          </v-col>
          <v-col class="searchable">
            {{ formatData(card.carte.userCreate) }}
          </v-col>
          <v-col cols="auto">
            <v-icon small color="grey">mdi-calendar</v-icon>
            <span class="caption ml-1 searchable">{{
              formatDate(card.createdAt)
            }}</span>
          </v-col>
        </v-row>

        <v-row dense class="mb-1">
          <v-col cols="6">
            <v-chip x-small label outlined color="primary" class="searchable">
              {{ formatData(card.carte.module) || "Aucun"  }}
            </v-chip>
          </v-col>
          <v-col cols="6">
            <v-chip x-small label outlined color="secondary" class="searchable">
              {{ formatData(card.carte.sous_module) || "Aucun" }}
            </v-chip>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
            <v-icon
              small
              :color="getColorByTeam(card.carte.impactType)"
              class="mr-1"
              >mdi-cog</v-icon
            >
            <span class="caption searchable">{{ card.carte.impactType && card.carte.impactType !== 'null' ? card.carte.impactType : 'Aucun'
              }}</span>
          </v-col>
          <v-col cols="6">
            <v-icon small color="success" class="mr-1"
              >mdi-source-branch</v-icon
            >
            <span class="caption searchable">{{ card.carte.originType && card.carte.originType !== 'null' ?
              card.carte.originType : 'Aucun'  
          }}</span>
          </v-col>
        </v-row>

        <template>
          <v-row v-if="card.carte.clientName && card.carte.clientName !== 'null'" 
          dense>
            <v-col cols="6" sm="6">
              <v-icon small color="primary" class="mr-1"
                >mdi-account-group</v-icon
              >
              <span class="caption searchable">{{
                card.carte.clientName
              }}</span>
            </v-col>

            <v-col v-if="card.carte.impactType" cols="6">
              <v-icon
                small
                :color="getColorByTeam(card.carte.impactType)"
                class="mr-1"
                >mdi-alert-circle</v-icon
              >
              <span class="caption searchable">{{
                card.carte.impactType
              }}</span>
            </v-col>
          </v-row>
        </template>

        <template v-if="card.carte.base && card.carte.base !== 'null'"
        >
          <v-row dense>
            <v-col cols="12">
              <v-icon small color="primary" class="mr-1">mdi-database</v-icon>
              <span class="caption searchable">{{ card.carte.base }}</span>
            </v-col>
          </v-row>
        </template>

        <template
          v-if="card.carte.assignement && card.carte.assignement.assignedTo"
        >
          <v-row dense>
            <v-col cols="12">
              <v-icon small color="warning" class="mr-1"
                >mdi-account-check</v-icon
              >
              <span class="caption searchable">
                Assigné à: {{ card.carte.assignement.assignedTo }}
              </span>
            </v-col>
          </v-row>
        </template>

        <template v-if="card.carte.duree">
          <v-row dense>
            <v-col cols="12">
              <v-icon small color="error" class="mr-1"
                >mdi-clock-outline</v-icon
              >
              <span class="caption searchable">
                Durée: {{ card.carte.duree.duree }} {{ card.carte.duree.unite }}
              </span>
            </v-col>
          </v-row>
        </template>

        <template v-if="card.carte.datePrevisionnel">
          <v-row dense>
            <v-col cols="12">
              <v-icon small color="purple" class="mr-1"
                >mdi-calendar-check</v-icon
              >
              <span class="caption searchable">
                Prévue: {{ formatDate(card.carte.datePrevisionnel) }}
              </span>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  name: "Carte",
  props: {
    isFromAllYear: {
      type: Boolean,
      default: false
    },
    card: {
      type: Object,
      required: true,
    },
    isTermine: {
      type: Boolean,
      default: false,
    },
    devTypeColors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCardShown: false,
    };
  },
  computed: {
    ...mapState(["informations", "userRightsById"]),
    // droits
    getTeam() {
      return this.userRightsById?.teamRole?.team || null;
    },
    canCreateTasks() {
      return this.userRightsById?.teamRole?.roles?.tasks?.create || false;
    },
    // Helper computed pour accéder aux droits
    taskRightsComputed() {
      return this.userRightsById?.teamRole?.roles?.tasks || null;
    },

    canWriteOwnTasks() {
      const connectedUser =
        this.informations?.firstname + " " + this.informations?.lastname;
      const taskCreator = this.card.carte.userCreate;
      // vérifier si le user connecté est le même que le user qui a créé la tâche
      const isTaskCreator = connectedUser === taskCreator;
      return isTaskCreator && this.taskRightsComputed?.writeOwnTasks;
    },

    canWriteTeamTasks() {
      // Récupérer l'équipe de l'utilisateur connecté
      const connectedUserTeam = this.getTeam;
      const taskDevType = this.card.carte.devType;

      // Vérifier si l'utilisateur a le droit d'écrire sur les tâches de son équipe
      const hasWriteTeamRight = this.taskRightsComputed?.writeTeamTasks;

      // Vérifier la correspondance entre l'équipe et le type de dev
      const isTeamMatching =
        (connectedUserTeam === "Développeur" &&
          taskDevType === "Développement") ||
        (connectedUserTeam === "Projet" && taskDevType === "Projet");

      // L'utilisateur doit avoir à la fois le droit d'écriture équipe
      // et appartenir à la bonne équipe par rapport au type de dev
      return connectedUserTeam && hasWriteTeamRight && isTeamMatching;
    },

    canEditOthersTasks() {
      return Boolean(this.taskRightsComputed?.editOthersTasks);
    },
    getColorByTeam() {
      return (devType) => {
        const color = this.$store.getters.getColorByDevType(devType);
        if (!color && color !== "#7b4e8e") {
          this.$reloadColors();
        }
        return color || "#7b4e8e";
      };
    },
  },
  methods: {
    toggleCardShow(event) {
      event.stopPropagation();
      this.isCardShown = !this.isCardShown;
    },
    isCardLinked(card) {
      return (
        card.carte.linkedCard &&
        card.carte.linkedCard !== "null" &&
        card.carte.linkedCard !== ""
      );
    },
    isCardAssigned(card) {
      return card.carte.assignement && card.carte.assignement.assigned;
    },
    formatData(data) {
      if (Array.isArray(data)) {
        return data.join(", ");
      }
      return data ? data.replace(/[\][\]"]+/g, "") : "";
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  watch: {
    card: {
      immediate: true,
      handler(newCard) {
        this.isCardShown = false;
      },
    },
    isFromAllYear: {
      immediate: true,
      handler(val) {
        if (val) {
          this.isCardShown = true;
        }
      }
    }
  },
  async created() {
    await this.$store.dispatch("initializeRightsById", this.informations.id);
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.highlighted {
  border: 2px solid var(--v-primary-base);
}

.linked-card {
  border-left: 4px solid var(--v-warning-base);
}

.searchable {
  word-break: break-word;
}

.v-card:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.v-expand-transition-enter-active,
.v-expand-transition-leave-active {
  transition: all 0.3s ease-in-out;
}

.caption {
  font-size: 0.75rem !important;
}

.v-chip.v-chip--outlined {
  border-width: 1px;
}

.v-list-item {
  min-height: 35px !important;
}

.v-list-item__title {
  font-size: 0.875rem;
}
</style>
